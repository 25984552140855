// Here you can add other styles

.CustomEditor {
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 10px;
  box-shadow: inset 0 1px 8px -3px #ababab;
  background: #fefefe;

  .DraftEditor-root {
    padding-top: 10px;
  }

  button {
    padding: 2px;
  }
}

.suggestions-list {
  border: 1px solid #00000036;
  padding: 8px 12px;
  margin-top: 3px;

  .suggestion {
    display: inline-block;
    margin-bottom: 2px;
    font-weight: 500;
    background-color: rgb(230, 243, 255);
  }

  >span {
    font-size: 0.9rem;
  }
}

.image-url-wrapper {}

.textAlignRight {
  text-align: right;
}

.textAlignMiddle {
  text-align: center;
}

.redeem-giftcard-border {
  border: 1px solid #e3e3e3;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  padding: 4px 8px;
  border-radius: 10px;
}

.textAlignLeft {
  text-align: left;
}

.fade-in {
  animation: fadeIn .35s ease-in-out forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

.editorButtons>* {
  margin-right: 10px !important;
}

.copy-icon {
  cursor: pointer;
  color: #8b949e !important;
  margin-top: -.3rem !important;
  font-size: 1.1rem !important;
  height: 1.1rem !important;
  width: 1.1rem !important;
  border: 1px solid transparent !important;

  &:hover {
    color: rgb(41, 41, 157) !important;
    fill: rgb(41, 41, 157) !important;
  }
}

.tooltip.top .tooltip-arrow,
.tooltip-inner {
  border: none;
  box-shadow: none;
  outline: none;
}

.calendar-icon {
  cursor: pointer;
  color: #8b949e !important;
  margin-top: -.3rem !important;
  font-size: 1.1rem !important;
  height: 1.1rem !important;
  width: 1.1rem !important;
  border: 1px solid transparent !important;

  &:hover {
    color: rgb(41, 41, 157) !important;
    fill: rgb(41, 41, 157) !important;
  }
}

.giftcard {
  min-height: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 2px 8px 15px 3px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  text-align: center;

  &.redeemed {
    background-color: #321fdb;
  }

  &.active {
    background-color: #2eb85c;
  }

  &.inactive {
    background-color: #e55353;
  }

  transition: background-color .25s ease;
}

.autosuggestion-listitem {
  cursor: pointer;

  &.suggested {
    background-color: #e6f3ff;
  }

  &:hover {
    background-color: #e6f3ff;
  }
}

label {
  margin-bottom: 3px;
  margin-top: .35rem;
}

.accordion-item {
  margin-bottom: 25px;
  border-radius: 0.25rem;
  border: 1px solid #d8dbe0;
}

.accordion-button {
  box-shadow: none !important;
  outline: 0 !important;
}

.accordion-header {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-body {
  border-bottom-left-radius: 0.25rem;
  border-top: 1px solid #d8dbe0;
  padding: 0px !important;

  border-bottom-right-radius: 0.25rem;
  margin-bottom: 25px;
}


.rmsc {
  --rmsc-main: #4285f4 !important;
  --rmsc-hover: #f1f3f5 !important;
  --rmsc-selected: #e2e6ea !important;
  --rmsc-border: rgb(216, 219, 224) !important;
  --rmsc-gray: #aaa !important;
  --rmsc-bg: #fff !important;
  --rmsc-radius: 0.25rem !important;
  /* Radius */
  --rmsc-h: calc(1.5em + 0.75rem + 2px) !important;
  /* Height */
}

.rmsc:focus {
  color: #768192;
  background-color: #fff;
  border-color: #958bef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(50 31 219 / 25%);
}

.rmsc .dropdown-container:focus-within {
  box-shadow: 0 0 0 0.2rem rgb(50 31 219 / 25%) !important;
  border-color: #958bef !important;
}

.c-sidebar-nav-link {
  text-transform: capitalize;
}

tr:has(> td.Verbose) {
  background-color: var(--warning);
}

tr:has(> td.READ) {
  background-color: var(--secondary);
}

tr:has(> td.WRITE) {
  background-color: var(--primary);
  color: white;
}

tr:has(> td.CREATE) {
  background-color: var(--success);
  color: white;
}

tr:has(> td.DELETE) {
  background-color: var(--danger);
  color: white;
}

.badge-http {
  background-color: lighten($color: black, $amount: 0.8);
  color: white;
}

.no-autosteps {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

.no-autosteps::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-autosteps::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pointProductImageArea {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  border: 1px solid #d8dbe0;
  border-radius: 12px;

  .pointProductImage {
    position: relative;
    display: flex;
    align-items: center;
    height: 120px;
    width: 120px;
    justify-content: center;

    >img {
      height: 100%;
      width: 100%;
      padding: 5px;
      object-fit: contain;
    }
  }
}


.readonly-quill-editor {
  div.ql-toolbar {
    display: none;
  }

  div.ql-container {
    border: 0px solid #ccc !important;
  }
}