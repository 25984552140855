.profil-picture {
    border-radius: 50%;
    height: 200px;
    width: 200px;
    border: 1px solid #d8dbe0;
}

.profil-picture-area {
    display: flex;
    align-items: center;

    .profil-picture {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        >img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            position: absolute;
        }

        &:hover {
            .profil-picture-overlay {
                opacity: 1;
            }
        }

        border-radius: 50%;
        height: 200px;
        width: 200px;
        border: 1px dashed #d8dbe0;
    }

    .profil-picture-overlay {
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        height: 200px;
        width: 200px;
        background-color: #d8dbe025;
        transition: opacity .15s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        >* {
            font-size: 40px !important;
            height: 40px !important;
            fill: black;
            color: black;
            width: 40px !important;
        }
    }
}

.profile-picture-wrapper {
    height: 24px;
    border-radius: 50%;
    border: 1px solid transparent;
    width: 24px;

    >img {
        overflow: hidden;
        height: 24px;
        border-radius: 50%;
        width: 24px;
    }
}

.comment-header {
    display: flex;
    align-items: center;
    flex-direction: row;

    >* {
        margin-right: 5px;
    }
}