.integration-card {
    transition: border .25s ease;
    &:hover {
        border: 1px #321fdb solid;
    }

    .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 55%;
        }
    }

    .integration-badge {
        position: absolute;
        top: 0%;
        right: 2%;
        padding: 5px;
        >span {
            font-size: .8rem !important;
        }
    }
}